import react from "react";
import {Helmet} from "react-helmet-async"
function faq() {
    <Helmet>
   <title>Cimeza Africa</title>
   <meta name="description" 
   content="Cimeza Africa SEO & Digital Marketing Agency, social media, branding ,web design and mobile application development, events"
   data-rh="true"/>

  <link rel="canonical" href="https://www.cimeza-africa.com/" />
  <link rel="alternate" href="https://www.cimeza-africa.com/" hreflang="en-eg" />
  <meta property="og:title" content="Cimeza Africa the Digital Marketing Agency social media, branding web design&amp; events | cimeza" />
  <meta property="og:description"
  content="Cimeza Africa, SEO & Digital Marketing Agency, social media, branding ,web design and mobile application development, events" />
  <meta property="og:url" content="https://www.cimeza-africa.com/" />
  <meta property="og:site_name" content="Cimeza Africa" />
<link rel="canonical"  href="/faq"/>
        
</Helmet> 
    return (

        <>
            {/* <!-- Start Breadcrumb  ============================================= --> */}
            <div className="breadcrumb-area bg-gradient text-center">
                {/* <!-- Fixed BG --> */}
                <div className="fixed-bg"></div>
                {/* <!-- Fixed BG --> */}
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <h1>Question & Answer</h1>
                            <ul className="breadcrumb">
                                <li><a href="#"><i className="fas fa-home"></i> Home</a></li>
                                <li><a href="#">Pages</a></li>
                                <li className="active">faq</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- End Breadcrumb --> */}

            {/* <!-- Star Faq ============================================= --> */}
            <div className="faq-area custom-padding">
                <div className="container">
                    <div className="faq-items">
                        <div className="row align-center">

                            <div className="col-lg-6">
                                <div className="faq-content wow fadeInUp">
                                    <div className="accordion" id="accordionExample">
                                        <div className="card">
                                            <div className="card-header" id="headingOne">
                                                <h4 className="mb-0" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    Where can I get Sample of your products?
                                                </h4>
                                            </div>

                                            <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                                <div className="card-body">
                                                    <p>
                                                        We are located along Lenena road Kilimani you can walk in to our office and have a look at our sample products 
                                                        or alternatively you can navigate to  our projects page.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" id="headingTwo">
                                                <h4 className="mb-0 collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    What software / IT related services do you offer?
                                                </h4>
                                            </div>
                                            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                                <div className="card-body">
                                                    <p>
                                                        We offer fullstack software development that is mobile,desktop and custom software applications.
                                                        we also offer internet of things solutions  (IOT ),automation software re-engineering for more detailed
                                                        information visit web and application development URL.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" id="headingThree">
                                                <h4 className="mb-0 collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    Where can i get your Catalogues?
                                                </h4>
                                            </div>
                                            <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                                <div className="card-body">
                                                    <p>
                                                       You can always access our catalogues online just navigate to  view all services page on top navigation
                                                        then click on view all  services button.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="thumb wow fadeInLeft" data-wow-delay="0.5s">
                                    <img src="assets/img/illustration/17.png" alt="Cimeza Africa" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- End Faq --> */}

            {/* <!-- Start Contact Area ============================================= --> */}
            <div id="contact" className="contact-us-area default-padding-bottom">
                <div className="container">
                    <div className="row">

                    <div className="col-lg-4 wow fadeInUp address text-light">
                            <div className="address-items">
                                <div className="item">
                                    <h4>Office Location</h4>
                                    <div className="info">
                                        <p> Jumuia Place<br /> Lenana Road, Kilimani<br /> </p>
                                    </div>
                                </div>
                                <div className="item">
                                    <h4>Contact Info</h4>
                                    <div className="info">
                                        <ul>
                                            <li>Phone: <span>+254110496946</span></li>
                                            {/* <li>Fax: <span>+44-20-7328-4499</span></li> */}
                                            <li>Email: <span>info@cimeza-africa.com</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 contact-form wow fadeInLeft">
                            <h2>Still no luck? <strong>Ask your questions</strong></h2>
                            <form action="https://webhunt.store/themeforest/digilab/assets/mail/contact.php" method="POST" className="contact-form">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <input className="form-control" id="name" name="name" placeholder="Name" type="text"  required/>
                                            <span className="alert-error"></span>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input className="form-control" id="email" name="email" placeholder="Email*" type="email" required />
                                            <span className="alert-error"></span>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input className="form-control" id="phone" name="phone" placeholder="Phone" type="number"  required/>
                                            <span className="alert-error"></span>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group comments">
                                            <textarea className="form-control" id="comments" name="comments" placeholder="please talk to us Us  *" required></textarea>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <button type="submit" name="submit" id="submit">
                                            Send Message <i className="fa fa-paper-plane"></i>
                                        </button>
                                    </div>
                                    {/* <!-- Alert Message --> */}
                                    <div className="col-lg-12 alert-notification">
                                        <div id="message" className="alert-msg"></div>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
            {/* <!-- End Contact --> */}
        </>
    );
}

export default faq;