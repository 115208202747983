import React, { useState } from 'react';
import products from "../data/products.json";


function Test() {
const [products, setproducts] = useState([]);

  return (
    <>
  
   
      <h1>welcome</h1>
      
    </>
  );
}

export default Test;


