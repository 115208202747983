import { createSlice } from "@reduxjs/toolkit";
import GetSignleProduct from "../Services/FetchProduct.service";
import { initialState } from "../../helpers/State";

const ProductSlice = createSlice({
    name: "Fetch Product",
    initialState,

    extraReducers: (builder) => {
        builder
        .addCase(GetSignleProduct.pending, (state) => {
            state.status = "Loading";
            state.error = "";
            state.product = [];
        })
        .addCase(GetSignleProduct.fulfilled, (state, action) => {
            state.status = "Loaded";
            state.error = "";
            state.product = action.payload;
        })
        .addCase(GetSignleProduct.rejected, (state, action) => {
            state.status = "Failed";
            state.error = action.error.message;
            state.product = [];
        })
    }
});

export default ProductSlice.reducer;