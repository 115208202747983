/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter,  Routes, Route} from "react-router-dom";
import About from './view/about';
import Contact from './view/Contact';
import Catalogues from './view/Catalogues';
import Home from './view/home';
import Projects from './view/Projects';
import Header from './components/header';
import Footer from './components/Footer';
import Services from './view/Services';
import Process from './view/process';
import FAQ from './view/faq';
import BLOG from './view/blog';
import My404Component from './view/error';
import Web_app from './view/web_app';
import Seo from './view/seo';
import Creative from './view/creative';
import Social from './view/social';
import MarketingStrategy from './view/marketing_strategy';
import Branding_signages from './view/branding_signages';
import Events from './view/events';
import Graphic from './view/graphic';
import ShortVideos from './view/shortVideos';
import DataFetching from './components/DataFetching';
import { Provider } from "react-redux";
import store from "./redux/store";
import ProductDetails from "./containers/ProductDetails";
import ProductPage from "./containers/ProductPage";
import ProductComponent from "./containers/ProductComponent";
import Form from './components/form/Form';
import Test from './components/test/test';
import ProductForm from './containers/ProductForm';
<script src="https://unpkg.com/react-router-dom/umd/react-router-dom.min.js"></script>
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <Provider store={store}>
      <Header/>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route  path="/about" element={<About />} />
          <Route  path="/Contact" element={<Contact />} />
          <Route  path="/Catalogues" element={<Catalogues />} />
          <Route  path="/Services" element={<Services />} />
          <Route  path="/process" element={<Process />} />
          <Route  path="/faq" element={<FAQ />} />
          <Route  path="/blog" element={<BLOG />} />
          <Route  path="/Projects" element={<Projects />} />
          <Route  path="social" element={<Social />} />
          <Route  path="app_dev" element={<Web_app />} />
          <Route  path="seo" element={<Seo />} />
          <Route  path="strategy" element={<MarketingStrategy />} />
          <Route  path="creative" element={<Creative />} />
          <Route  path="brandingSignage" element={<Branding_signages />} />
          <Route  path="events" element={<Events/>} />
          <Route  path="ShortVideos" element={<ShortVideos/>} />
          <Route  path="graphic" element={<Graphic/>} />
          <Route  path="shop" element={<DataFetching/>} />
          <Route  path="ProductPage" element={<ProductPage/>} />
          <Route path="product/:productId" element={<ProductDetails />} />
          <Route  path="ProductComponent" element={<ProductComponent/>} />
          <Route  path="Products" element={<DataFetching/>} />
          <Route  path="/Form" element={<Form/>} />
          <Route  path="/ProductForm" element={<ProductForm/>} />
          <Route  path="/test" element={<Test/>} />
          
          
          <Route path='*' exact={true} element={<My404Component />} />
F
        </Routes>
      <Footer/>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);