import React from "react";
import {NavLink} from "react-router-dom";
import '../App.css';
function Web_app() {
  return (
    <>
      <div class="container custom_container">
        <div class="card borderr-none">
          <div class="row ">
          <div class="col-md-5 ">
            
              <img
                src="https://www.inexture.com/wp-content/uploads/2020/03/Group-498.svg"
                className="service-picha"
              />
            </div>
            <div class="col-md-7 ">
              <div class="card-block custom_padding">
                <h4 class="card-title">MOBILE & WEB APPLICATIONS</h4>
                <p class="card-text">
                The ability to develop beautiful, responsive websites is one of our strongest assets. Whether it’s a Social Media platform,
                 ecommerce, or an informative website, we provide our clients with support the entire journey, from design and UX to search 
                 engine optimisation – just take a look at some of our work! <NavLink to="/projects"> <span className="btn btn-outline-primary">Our Work</span></NavLink>
                </p>
                <br/><br/>
                 
                <a className="btn circle btn-md custom-blue text-white fw-bold">
                 <NavLink to="/Contact"> <span className="text-white">Request this Service</span></NavLink>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Web_app;
