import React from "react";
import { NavLink } from "react-router-dom";
import "../App.css";
function MarketingStrategy() {
  return (
    <>
      <div class="container custom_container">
        <div class="card borderr-none">
          <div class="row ">
            <div class="col-md-5 ">
              <img
                src="https://cdn.pixabay.com/photo/2020/05/18/16/17/social-media-5187243__480.png"
                className="service-picha"
              />
            </div>
            <div class="col-md-7 ">
              <div class="card-block custom_padding">
                <h4 class="card-title fw-bold custom-card-title">
                  Marketing Strategy{" "}
                </h4>
                <p class="card-text">
                  We focus on delivering processes, experiences, and intangibles
                  to customers rather than physical goods and transactions. We
                  ensure that we integrate and focus on the customer throughout
                  the firm and across all functions.We ensure that all your
                  companys functions – marketing, selling, human resources and
                  operations, work together for an effective marketing strategy.
                  Rather than the traditional goods marketing focus on
                  transactions and exchange, services marketing strategy is
                  centered on the customer, usage, and relationships
                </p>
                <br />

                <a className="btn circle btn-md custom-blue text-white fw-bold">
                  <NavLink to="/Contact">
                    {" "}
                    <span className="text-white">Request this Service</span>
                  </NavLink>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MarketingStrategy;
