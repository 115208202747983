import React from "react";
import { NavLink } from "react-router-dom";
import "../App.css";
function Branding_signages() {
  return (
    <>
      <div class="container custom_container">
        <div class="card borderr-none">
          <div class="row ">
            <div class="col-md-5 ">
              <img src="https://images.unsplash.com/photo-1573216755088-971e32839531?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDN8fHxlbnwwfHx8fA%3D%3D&w=1000&q=80" className="service-picha" alt="Cimeza Africa" />
            </div>
            <div class="col-md-7 ">
              <div class="card-block custom_padding">
                <h4 class="card-title fw-bold custom-card-title">
                  Branding & Signages{" "}
                </h4>
                <p class="card-text">
                  Your brand needs to be memorable, distinctive & beautiful.
                  We'll work with you to develop your visual identity,
                  messaging, positioning, voice & values, so you can resonate
                  with your audience.
                </p>
                <br />

                <a className="btn circle btn-md custom-blue text-white fw-bold">
                  <NavLink to="/Contact">
                    {" "}
                    <span className="text-white">Request this Service</span>
                  </NavLink>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Branding_signages;
