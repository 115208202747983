import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { productState } from "../../helpers/State";
import CreateProduct from "../../redux/Services/NewProduct"
import "./form.css";

function Form() {
  const [state, setState] = useState(productState);
  const [image, setimage] = useState(null);
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();
   
  const changeHandler = (e) => {
    setState({...state, [e.target.name]: e.target.value});
  }

  const ClearNotice = () => {
    setTimeout(() => {
      setMessage("");
    }, 5000);
  }
  // handle form data
  const submitHandler = (e) => {
    e.preventDefault();
    console.log("Inside submit handler!");
    if((state.name !=="" && state.description !=="" && state.price !=="" && state.product_type !=="" &&
       state.discount !=="" && state.units !=="" && state.category !=="") && image !== null) {
        const productData = new FormData();
        productData.append("product[name]", state.name);
        productData.append("product[description]", state.description);
        productData.append("product[price]", state.price);
        productData.append("product[product_type]", state.product_type);
        productData.append("product[discount]", state.discount);
        productData.append("product[units]", state.units);
        productData.append("product[category", state.category);
        productData.append("product[image]", image);
        if(dispatch(CreateProduct(productData))){
          e.target.files = null;
          setState(productState);
          setMessage("Product created successfully!");
          ClearNotice();
        } else{
          setMessage("There was an error creating the product, try again later.")
          ClearNotice();
        }
    }
    else{
       setMessage("Please enter data in all form input fields!");
       ClearNotice();
    }
  }

  return (
    <>
      <div className="form-container container">
        <form className="row g-3 text-dark" onSubmit={submitHandler}>
          <div className="col-12 mt-5 text-center"><h2 className="text-primary">Create New Product</h2></div>
          <div classNAme="col-md-6 mb-4">
            <label htmlFor="name  " className="form-label">
             Name
            </label>
            <input
          className="form-control  text-dark"
          type="text"
          name="name"
          value={state.name}
          placeholder="Name"
          onChange={changeHandler}
        />
          </div>
          <div className="col-md-6 mb-4">
            <label htmlFor="description" className="form-label">
              Description
            </label>
            <input
          className="form-control text-dark"
          type="text"
          name="description"
          value={state.description}
          placeholder="description"
          onChange={changeHandler}
        />
          </div>
          <div className="col-md-6 mb-4">
            <label htmlFor="price" className="form-label">
             Price
            </label>
            <input
         className= 'form-control text-dark'
          type="text"
          value={state.price}
          name="price"
          placeholder="price"
          onChange={changeHandler}
        />
          </div>
          <div className="col-md-6 mb-4">
            <label htmlFor="product_type" className="form-label">
              Product Type
            </label>
            <input
          className= 'form-control text-dark'
          type="text"
          value={state.product_type}
          name="product_type"
          placeholder="product_type"
          onChange={changeHandler}
        />
          </div>
          <div className="col-md-6 mb-4">
            <label htmlFor="discount" className="form-label">
             Discount
            </label>
            <input
        className= 'form-control text-dark'
          type="text"
          name="discount"
          value={state.discount}
          placeholder="discount"
          onChange={changeHandler}
        />
          </div>
          <div className="col-md-6 mb-4">
            <label htmlFor="units" className="form-label">
              Units
            </label>
            <input
        className= 'form-control mb-2 text-dark'
          type="text"
          name="units"
          value={state.units}
          placeholder="units"
          onChange={changeHandler}
        />
          </div>
          <div className="col-md-6 mb-4">
            <label htmlFor="category" className="form-label">
             Category
            </label>
            <input
        className= 'form-control text-dark'
          type="text"
          value={state.category}
          name="category"
          placeholder="category"
          onChange={changeHandler}
        />
          </div>
          <div className="col-md-6 mb-4">
          <input
        className= 'form-control text-dark'
          type="file" 
              accept="image/*"
              placeholder="Image"
              name="image" 
          onChange={(e) => setimage(e.target.files[0])}
        />
          </div>
          <div className="col-12 text-center mt-4 mb-4">
            <button type="submit" class="btn btn-primary p-3">
             Create product
            </button>
            <div className="message">{message ? <p className="text-primary mt-4"><strong>{message}</strong></p> : null}</div>
          </div>
        </form>
      </div>
    </>
  );
}

export default Form;
