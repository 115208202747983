import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { image_url } from '../helpers/config';
import './product.css'


const ProductComponent = () => {
  const products = useSelector((state) => state.allProducts.products);
  
  const renderList = products.map((product) => {
    const { id, name, image, price, category } = product;
    return (
      <>
   <div className="four wide column" key={id}>
<Link to={`/product/${id}`}>
  <div className="ui link cards" >
    <div className="card " style={{borderBottom:'none', boxShadow: 'none'}} >
      {/* style={{borderBottom:'none', boxShadow: 'none'}} */}
      <div className="image">
       <img src={`${image_url}${image}`} alt={name} />
      </div>
      <div className="content">
        <div className="header">{name}</div>
        <div className="meta price">Get a Quote <i class="fa fa-cart-shopping"></i></div>
        <div className="meta">{category}</div>
      </div>
    </div>
  </div>
  
</Link>
</div>

      </>
    
    );
  });
  return <>{renderList}</>;
};

export default ProductComponent;
