import React, { useEffect, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setProducts } from "../redux/actions/productsActions";
import ProductComponent from "./ProductComponent";
import FetchProducts from "../redux/Services/FetchProducts.service";

const ProductPage = () => {
  const [product, setProduct] = useState("");
  const products = useSelector((state) => state.allProducts.products);
  const dispatch = useDispatch();
 
  useEffect(() => {
    if (products.length === 0) {
      dispatch(FetchProducts(setProduct));
    }
    
  }, [products.length]);

  // console.log("Products :", products);
  return (
    <div className="ui grid container">
      <ProductComponent />
    </div>
  );
};

export default ProductPage;
