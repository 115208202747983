import React from "react";
import { NavLink } from "react-router-dom";
import "../App.css";
function Graphic() {
  return (
    <>
      <div class="container custom_container">
        <div class="card borderr-none">
          <div class="row ">
            <div class="col-md-5 ">
              <img
                src="https://img.freepik.com/free-vector/tiny-graphic-designer-drawing-with-big-pen-computer-screen-creators-work-creative-woman-working-laptop-flat-vector-illustration-digital-design-concept-banner-landing-web-page_74855-25342.jpg?w=2000"
                className="service-picha"
              />
            </div>
            <div class="col-md-7 ">
              <div class="card-block custom_padding">
                <h4 class="card-title custom-card-title">Graphic Design</h4>
                <p class="card-text">
                  Content marketing has become more popular in advertising with
                  more companies relying heavily on images and infographics to
                  promote their brands. We help you make use of graphic designs
                  to convey meaningful information to your target clients.
                  Research shows that our eyes collect 90% of the information
                  that we gather while our brains are programmed to identify an
                  alluring image in seconds. Therefore, it would be hard to
                  ignore the importance of graphic designing in attracting your
                  target market to a brand and increasing your sales.
                </p>
                <br />
                <br />

                <a className="btn circle btn-md custom-blue text-white fw-bold">
                  <NavLink to="/Contact">
                    {" "}
                    <span className="text-white">Request this Service</span>
                  </NavLink>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Graphic;
