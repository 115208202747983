import React, {useState} from 'react';
import {NavLink} from "react-router-dom";
import Cards from "../components/Cards";
import '../App.css';

const Services = () => {
  return (
    <>
    
      {/* <!-- Start Breadcrumb  ============================================= --> */}
      <div className="breadcrumb-area custom-blue text-center">
        <div className="fixed-bg"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1>Our Services</h1>
              <ul className="breadcrumb">
                <li>
                  <a href="/">
                    <i className="fas fa-home"></i> Home
                  </a>
                </li>
                <li>
                  <a href="#">Pages</a>
                </li>
                <li className="active">Services</li>
                
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Breadcrumb --> */}

      {/* <!-- Start Our Benefits  ============================================= --> */}
      <div className="benifits-area bg-gray custom-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 wow fadeInUp info">
              <h5>Our Benefits</h5>
              <h2>
                Get More Trafic <strong>On Your Website</strong>
              </h2>
              <p>
                The Digital space in Kenya is crowded with competition. This
                calls for creative concepts to capture the attention of the
                online audience. Our digital agency seeks to perfect your brand
                through these unique concepts capturing the hearts of your
                audience online to convert them into buying customers and
                eventually turn them into loyal followers. We collaborate with
                our clients and ensure transparency throughout the project life.
                Creativity, effective communication and engagement, teamwork and
                collaboration has helped us to grow small businesses online.
              </p>
              <ul>
                <li>
                  <h5>Global Reach</h5>
                </li>
                <li>
                  <h5>Big Experience</h5>
                </li>
                <li>
                  <h5>Convenience</h5>
                </li>
                <li>
                  <h5>Team Strength</h5>
                </li>
              </ul>
              <a className="btn btn-sm btn-gradient ">Get Started Now</a>
            </div>
            <div className="col-lg-6 wow fadeInLeft thumb">
              <img src="assets/img/illustration/3.png" alt="cimeza-africa"/>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Our Benefits --> */}

      {/* <!-- Start Services Area ============================================= --> */}
  <div className='container mt-3'>
  <div class="row row-cols-1 row-cols-md-3 g-4">
  <div class="col">
    <div class="card h-100 custom-card">
      <img src="https://cdn-icons-png.flaticon.com/512/1968/1968777.png" class="card-img-top custom-card-img" alt="Cimeza Africa"/>
      <div class="card-body text-center">
        <h5 class="card-title fw-bold text-warning custom-card-title">Social Media Management</h5>
        <p>
           all-rounded brand management that covers regular updates and reputation management </p>
       
      </div>

      <button className='btn'><i className="fas fa-arrow-right text-primary"></i> <NavLink to="/social">More</NavLink> </button>
      
    </div>
  </div>
  <div class="col">
    <div class="card h-100 custom-card">
      <img src="https://cdn-icons-png.flaticon.com/512/2919/2919740.png" class="card-img-top custom-card-img" alt="Cimeza Africa"/>
      <div class="card-body text-center">
        <h5 class="card-title fw-bold text-warning custom-card-title">Search Engine Optimization</h5>
        <p> Generate target traffic through major search engines. Search engine optimization is an amazing 
          long term online marketing strategy  </p>

      </div>
      <a className='btn'><i className="fas fa-arrow-right text-primary"></i> <NavLink to="/seo">More</NavLink> </a>
    </div>
  </div>
  <div class="col">
    <div class="card h-100 custom-card">
      <img src="https://cdn-icons-png.flaticon.com/512/2645/2645725.png" class="card-img-top custom-card-img" alt="Cimeza Africa"/>
      <div class="card-body text-center">
        <h5 class="card-title fw-bold  text-warning custom-card-title">Mobile App And Web Development</h5>
        <p>The ability to develop beautiful, responsive apps is one of our strongest assets. </p>

      </div>
      <a className='btn'><i className="fas fa-arrow-right text-primary"></i> <NavLink to="/app_dev">More</NavLink> </a>
    </div>
  </div>
  <div class="col mt-sm-4">
  <div class="card h-100 custom-card">
      <img src="https://cdn-icons-png.flaticon.com/512/3579/3579143.png" class="card-img-top custom-card-img" alt="Cimeza Africa"/>
      <div class="card-body text-center">
        <h5 class="card-title fw-bold text-warning custom-card-title">SEO</h5>
        <p> Generate target traffic through major search engines. Search engine optimization is an amazing long term online marketing strategy</p>

      </div>
      <a className='btn'><i className="fas fa-arrow-right text-primary"></i> <NavLink to="/seo">More</NavLink> </a>
    </div>
  </div>

  <div class="col mt-sm-4">
  <div class="card h-100 custom-card">
      <img src="https://cdn-icons-png.flaticon.com/512/3234/3234960.png" class="card-img-top custom-card-img" alt="Cimeza Africa"/>
      <div class="card-body text-center">
        <h5 class="card-title fw-bold text-warning custom-card-title">Creative Design</h5>
        <p>Preparation of all digital assets such as banners, gifs, and short social media videos.Graphics and much more </p>

      </div>
      <a className='btn'><i className="fas fa-arrow-right text-primary"></i> <NavLink to="/creative">More</NavLink> </a>
    </div>
  </div>

  <div class="col mt-sm-4">
  <div class="card h-100 custom-card">
      <img src="https://cdn-icons-png.flaticon.com/512/3141/3141181.png" class="card-img-top custom-card-img" alt="Cimeza Africa"/>
      <div class="card-body text-center">
        <h5 class="card-title fw-bold text-warning custom-card-title">Digital Marketing Strategy</h5>
        <p>
        Formulation of key go-to-market plans, competitor reserch, market analysis  for your business
                                        </p>

      </div>
      <a className='btn'><i className="fas fa-arrow-right text-primary"></i> <NavLink to="/strategy">More</NavLink> </a>
    </div>
  </div>

  {/* additional services */}

  <div class="col mt-sm-4">
  <div class="card h-100 custom-card">
      <img src="https://cdn-icons-png.flaticon.com/512/2405/2405093.png" class="card-img-top custom-card-img" alt="Cimeza Africa"/>
      <div class="card-body text-center">
        <h5 class="card-title fw-bold text-warning custom-card-title">Branding</h5>
        <p>
        creating a distinct identity for a business in the mind of your target audience and consumers. At the the most basic level........
                                        </p>

      </div>
      <a className='btn'><i className="fas fa-arrow-right text-primary"></i> <NavLink to="/brandingSignage">More</NavLink> </a>
    </div>
  </div>

  
  <div class="col mt-sm-4">
  <div class="card h-100 custom-card">
      <img src="https://cdn-icons-png.flaticon.com/512/4358/4358450.png" class="card-img-top custom-card-img" alt="Cimeza Africa"/>
      <div class="card-body text-center">
        <h5 class="card-title fw-bold text-warning custom-card-title">Events</h5>
        <p>
        We bring freshness, creativity and reliability to corporate event planning in Kenya. With end to end event design, planning ......
        </p>

      </div>
      <a className='btn'><i className="fas fa-arrow-right text-primary"></i> <NavLink to="/events">More</NavLink> </a>
    </div>
  </div>

  <div class="col mt-sm-4">
  <div class="card h-100 custom-card">
      <img src="https://cdn-icons-png.flaticon.com/512/2977/2977865.png" class="card-img-top custom-card-img" alt="Cimeza Africa"/>
      <div class="card-body text-center">
        <h5 class="card-title fw-bold text-warning custom-card-title">Marketing</h5>
        <p>
        We will help you in understanding your customer needs, translating those needs into products and services.
        </p>

      </div>
      <a className='btn'><i className="fas fa-arrow-right text-primary"></i> <NavLink to="/strategy">More</NavLink> </a>
    </div>
  </div>
</div>

</div>

<br/><br/>
      {/* <!-- End Services Area --> */}
      <Cards   componentHeader='Other Services we Offer'/>
     
      {/* <!-- Start Why Choose Us  ============================================= --> */}
      <div className="choose-us-area default-padding-bottom">
        <div className="container">
          <div className="row align-center">
            <div className="col-lg-6 wow fadeInUp thumb">
              <img src="assets/img/illustration/7.png " alt="Cimeza Africa"/>
            </div>
            <div className="col-lg-6 wow fadeInLeft info">
              <h2>
                The Marketing Agency <br /> <strong>To Grow your Brand</strong>
              </h2>
              <p>
                <strong>
                  We’re a marketing agency born in the age of social and
                  scaled-on results based in Nairobi, Kenya.
                </strong>
                <br />
                Finding solutions that really work for our clients is what we do
                best and what we love most. Our main job is to make sure our
                clients stand out from the crowd. We believe in the power of
                brands to make deeper connections with people, and deliver more
                value for your business.
              </p>
              <ul>
                <li>
                  <h5>Global Reach</h5>
                  <p>Upto 100%</p>
                </li>
                <li>
                  <h5>Big Experience</h5>
                  <p>Expert worker</p>
                </li>
                <li>
                  <h5>Convenience</h5>
                  <p>To reach your target</p>
                </li>
                <li>
                  <h5>Team Strength</h5>
                  <p>Clients satisfaction</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Why Choose Us --> */}
  
     {/* <!-- Start Testimonials  ============================================= --> */}
      <div className="testimonials-area default-padding">
        <div className="container">
          <div className="testimonial-items">
            <div className="row align-center">
              <div className="col-lg-5 wow fadeInUp">
                <div className="client-thumb">
                  <img src="assets/img/team/person1.jfif" alt="cimeza-africa" />
                  <img src="assets/img/team/person2.jpg" alt="cimeza-africa"/>
                  <img src="assets/img/team/person3.jpg" alt="cimeza-africa" />
                  <img src="assets/img/team/brian.png" alt="cimeza-africa" />
                </div>
              </div>
              <div className="col-lg-6 wow fadeInLeft offset-lg-1 testimonial-content">
                <div className="heading">
                  <h2>
                    What people say <br /> About our Services
                  </h2>
                </div>
                <div className="testimonials-carousel owl-carousel owl-theme">
                  {/* <!-- Single Item --> */}
                  <div className="item">
                    <p>
                      "They were the best in the game regarding email marketing
                      techniques, attention to detail, and communication."
                    </p>
                    <div className="author">
                      <div className="info">
                        <h5>George</h5>
                        <span>Marketing</span>
                      </div>
                    </div>
                    
                  </div>
                  {/* <!-- End Single Item -->
                            <!-- Single Item --> */}
                  <div className="item">
                    <p>
                      Cimeza Africa will transform to the next level.It is a
                      great agency to rely on for digital marketing purposes.
                    </p>
                    <div className="author">
                      <div className="info">
                        <h5>Evelyn</h5>
                        <span>Manager</span>
                      </div>
                    </div>
                  </div>
                  
                  {/* <!-- End Single Item --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Testimonials Area --> */}
      
    </>
  );
}

export default Services;
