import React, { useState, useEffect } from "react";
import {NavLink} from "react-router-dom";
import {Helmet} from "react-helmet-async"
import { useForm, ValidationError } from '@formspree/react';
const ProductForm = () => {
    const intialValues = { name: "", email: "", phone: "", message: "" };
    const [formValues, setFormValues] = useState(intialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);


    const addData = async (e) => {
       console.log('data added')

        
   } 


   const [state, handleSubmit] = useForm("mrgvgwpe");

    if (state.succeeded) {
        return <p className="text-success text-center fw-bold">Thanks for shopping with us!</p>;
    }

    <Helmet>
    <title>Cimeza Africa</title>
    <meta name="description" 
    content="Cimeza Africa SEO & Digital Marketing Agency, social media, branding ,web design and mobile application development, events"
    data-rh="true"/>
 
   <link rel="canonical" href="https://www.cimeza-africa.com/" />
   <link rel="alternate" href="https://www.cimeza-africa.com/" hreflang="en-eg" />
   <meta property="og:title" content="Cimeza Africa the Digital Marketing Agency social media, branding web design&amp; events | cimeza" />
   <meta property="og:description"
   content="Cimeza Africa, SEO & Digital Marketing Agency, social media, branding ,web design and mobile application development, events" />
   <meta property="og:url" content="https://www.cimeza-africa.com/" />
   <meta property="og:site_name" content="Cimeza Africa" />
 <link rel="canonical"  href="/contact"/>
         
 </Helmet> 
    return (
        
        <>

            {/* <!-- Start Breadcrumb ============================================= --> */}
            {/* <div className="breadcrumb-area bg-gradient text-center">
               
                <div className="fixed-bg" ></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <h1>Shop with us</h1>
                            <ul className="breadcrumb">
                                <li><a href="/"><i className="fas fa-home"></i> Home</a></li>
                                <li><NavLink to="/services">Services</NavLink></li>
                                <li><NavLink to="/ProductPage">Shop</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <!-- End Breadcrumb --> */}

            {/* <!-- Start Contact Area  ============================================= --> */}
            <div id="contact" className="contact-us-area custom-padding">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-4 wow fadeInUp address text-light">
                            <div className="address-items">
                                <div className="item">
                                    <h4>Office Location</h4>
                                    <div className="info">
                                        <p> Jumuia Place<br /> Lenana Road, Kilimani<br /> </p>
                                    </div>
                                </div>
                                <div className="item">
                                    <h4>Contact Info</h4>
                                    <div className="info">
                                        <ul>
                                            <li>Phone: <span>+254110496946</span></li>
                                            {/* <li>Fax: <span>+44-20-7328-4499</span></li> */}
                                            <li>Email: <span>info@cimeza-africa.com</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 wow fadeInLeft contact-form">
                            
                            <form onSubmit={handleSubmit} className="contact-form">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <input className="form-control" id="name" name="name" placeholder="Name" type="text"  required/>
                                            <span className="alert-error"></span>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input className="form-control" id="email" name="email" placeholder="Email*" type="email" required />
                                            <span className="alert-error"></span>
                                        </div>
                                        
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input className="form-control" id="phone" name="phone" placeholder="Phone" type="number" required/>
                                            <span className="alert-error"></span>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input className="form-control" id="productName" name="productName" placeholder="product name?" type="text" required/>
                                            <span className="alert-error"></span>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input className="form-control" id="productNUmber" name="productNUmber" placeholder="number of items you want?" type="number" required/>
                                            <span className="alert-error"></span>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group comments">
                                            <textarea className="form-control" id="description" name="description" placeholder="more description..." required></textarea>
                                        </div>

                                        
                                    </div>
                                    <div className="col-lg-12">
                                        <button type="submit" name="submit" id="submit"  disabled={state.submitting} onClick={addData}>
                                            place your order <i class="fa fa-cart-shopping"></i>
                                        </button>
                                    </div>
                                    {/* <!-- Alert Message --> */}
                                    <div className="col-lg-12 alert-notification">
                                        <div id="message" className="alert-msg"></div>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
           

        </>

    );
}

export default ProductForm;
