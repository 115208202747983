import { createSlice } from "@reduxjs/toolkit";
import CreateProduct from "../Services/NewProduct";
import { newProductState } from "../../helpers/State";

const createProductSlice = createSlice({
    name: 'Create Product',
    initialState: newProductState,
    
    extraReducers: (builder) => {
        builder
         .addCase(CreateProduct.pending, (state) => {
            state.status = "Loading";
            state.error = "";
            state.newproduct = [];
         })
         .addCase(CreateProduct.fulfilled, (state, action) => {
            state.status = "Created";
            state.error = "";
            state.newproduct = action.payload;
         })
         .addCase(CreateProduct.rejected, (state, action) => {
            state.status = "";
            state.error = action.error.message;
            state.newproduct = []
         })
    }
})

export default createProductSlice.reducer;