import { createSlice } from "@reduxjs/toolkit";
import FetchProducts from "../Services/FetchProducts.service";
import { initialState } from "../../helpers/State";

const FetchProductsSlice = createSlice({
    name:"Fetch Products",
    initialState, 

    extraReducers: (builder) => {
        builder
        .addCase(FetchProducts.pending, (state) => {
            state.status = "Loading";
            state.error = "";
            state.products = [];
        })
        .addCase(FetchProducts.fulfilled, (state, action) => {
            console.log("Data: ", action);
            state.status = "Loaded";
            state.error = "";
            state.products = action.payload;
        })
        .addCase(FetchProducts.rejected, (state, action) => {
            state.status = "Failed";
            state.error = action.error.message;
            state.products = [];
        })
    }
});

export default FetchProductsSlice.reducer;
