import React, { useState, useEffect } from "react";
import {Helmet} from "react-helmet-async"
import { useForm, ValidationError } from '@formspree/react';
const Contact = () => {
    <Helmet>
    <title>Cimeza Africa</title>
    <meta name="description" 
    content="Cimeza Africa SEO & Digital Marketing Agency, social media, branding ,web design and mobile application development, events"
    data-rh="true"/>
 
   <link rel="canonical" href="https://www.cimeza-africa.com/" />
   <link rel="alternate" href="https://www.cimeza-africa.com/" hreflang="en-eg" />
   <meta property="og:title" content="Cimeza Africa the Digital Marketing Agency social media, branding web design&amp; events | cimeza" />
   <meta property="og:description"
   content="Cimeza Africa, SEO & Digital Marketing Agency, social media, branding ,web design and mobile application development, events" />
   <meta property="og:url" content="https://www.cimeza-africa.com/" />
   <meta property="og:site_name" content="Cimeza Africa" />
 <link rel="canonical"  href="/catalogues"/>
         
 </Helmet> 
    return (
        
        <>

            {/* <!-- Start Breadcrumb ============================================= --> */}
            {/* <div className="breadcrumb-area bg-gradient text-center">
              <div className="fixed-bg" ></div>
              <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <h1>Catalogues</h1>
                            <ul className="breadcrumb">
                                <li><a href="/"><i className="fas fa-home"></i> Home</a></li>
                                <li><a href="#">Pages</a></li>
                                <li className="active">Contact</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <!-- End Breadcrumb --> */}

            {/* <div class="card">
  <img class="card-img-top" src="https://501438041880-zoomcatalog-assets.s3.amazonaws.com/22325/07db24e1557042c9bc634213ecfa3159/thumbnail_200.jpg" alt="Card image cap" target="_blank" />
    <div class="card-body">
      <h5 class="card-title">APPAREL & HEADWEAR</h5>
      <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
    </div>
    <div class="card-footer">
      <a href="https://viewer.zoomcats.com/promotional-apparel-and-headwear-2022-2023" className="btn custom-blue btn-block text-white">view</a>
    </div>
  </div> */}

<div className="container mt-4 custom-size">
<h1 className="custom-blue text-center text-white fw-bold">New Brand Catalogues Available</h1>

<div className="card-deck">
<div className="card">
<img src="./assets/img/catalogues/corporate-headwear.jpg" className="custom-size" alt="Cimeza Africa" />
    <div class="card-body">
      <h5 class="card-title">APPAREL & HEADWEAR</h5>
      
    </div>
    <div class="card-footer">
      <a href="https://viewer.zoomcats.com/promotional-apparel-and-headwear-2022-2023" className="btn custom-blue btn-block text-white" target="_blank" rel="noopener noreferrer">view</a>
    </div>
  </div>

  <div class="card">
  <img src="./assets/img/catalogues/altititude-workwear.jpg" className="custom-size" alt="Cimeza Africa" />
    <div class="card-body">
      <h5 class="card-title">ALTITUDE & WORKWEAR</h5>
       </div>
    <div class="card-footer">
      <a href="https://viewer.zoomcats.com/altitude-workwear-2021-2022/page/10" className="btn custom-blue btn-block text-white" target="_blank" rel="noopener noreferrer">view</a>
    </div>
  </div>
  
</div>

</div>
          
<br/>

<div className="container mt-4">
<h1 className="custom-blue text-center text-white fw-bold">Custom Packaging,Display & Promotional Products Catalogus</h1>

<div class="card-deck">
<div class="card">
<img src="./assets/img/catalogues/custom-display.jpg" className="logo" alt="Cimeza Africa" />
  {/* <img class="card-img-top" src="https://501438041880-zoomcatalog-assets.s3.amazonaws.com/22325/f4f576a3a7524889a98fb766780986a3/thumbnail_200.jpg" alt="Card image cap" target="_blank" /> */}
    <div class="card-body">
      <h5 class="card-title">custom packaging</h5>
       </div>
    <div class="card-footer">
      <a href="https://viewer.zoomcats.com/promotional-display" className="btn custom-blue btn-block text-white" target="_blank" rel="noopener noreferrer">view</a>
    </div>
  </div>
  
  <div class="card">
  <img src="./assets/img/catalogues/promotional-products.jpg" className="logo" alt="Cimeza Africa" />
    <div class="card-body">
      <h5 class="card-title">Promotional Products</h5>
       </div>
    <div class="card-footer">
      <a href="https://viewer.zoomcats.com/promotional-products-201920" className="btn custom-blue btn-block text-white" target="_blank" rel="noopener noreferrer">view</a>
    </div>
  </div>

  <div class="card">
  <img src="./assets/img/catalogues/promotional-altitude.jpg" className="logo" alt="Cimeza Africa" />
    <div class="card-body">
      <h5 class="card-title">Promotional Altitude products</h5>
    </div>
    <div class="card-footer">
      <a href="https://viewer.zoomcats.com/altitude-corporate-gifts-20192020" className="btn custom-blue btn-block text-white" target="_blank" rel="noopener noreferrer">view</a>
    </div>
  </div>
</div>

</div>


</>

    );
}

export default Contact;