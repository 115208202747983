import React from 'react'
function Card(props) {
  return (
    <>
    <div className="card remove-border">
       <img className="card-img-top text-center service-img rotate" src="https://cdn-icons-png.flaticon.com/512/1357/1357643.png" alt="cimeza-africa"/>
     <div className="card-body">
       <h5 className="card-title custom-card-title text-warning">{props.cardtitle}</h5>
       <p className="card-text">{props.cardtext}</p>
       
     </div>
   </div>
   </>
  )
}

export default Card
