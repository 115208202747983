/* eslint-disable jsx-a11y/anchor-is-valid */
import react from "react";
import {NavLink} from "react-router-dom";
import {Helmet} from "react-helmet-async"

function error ()

{
    return (
        <>
<Helmet>
   <title>Cimeza Africa</title>
   <meta name="description" 
   content="Cimeza Africa SEO & Digital Marketing Agency, social media, branding ,web design and mobile application development, events"
   data-rh="true"/>

  <link rel="canonical" href="https://www.cimeza-africa.com/" />
  <link rel="alternate" href="https://www.cimeza-africa.com/" hreflang="en-eg" />
  <meta property="og:title" content="Cimeza Africa the Digital Marketing Agency social media, branding web design&amp; events | cimeza" />
  <meta property="og:description"
  content="Cimeza Africa, SEO & Digital Marketing Agency, social media, branding ,web design and mobile application development, events" />
  <meta property="og:url" content="https://www.cimeza-africa.com/" />
  <meta property="og:site_name" content="Cimeza Africa" />
<link rel="canonical"  href="/error"/>
        
</Helmet> 
       
         {/* <!-- Start Breadcrumb ============================================= --> */}
    <div className="breadcrumb-area bg-gradient text-center">
        {/* <!-- Fixed BG --> */}
        <div className="fixed-bg" ></div>
        {/* <!-- Fixed BG --> */}
        <div className="container">
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                    <h1>Error Page</h1>
                    <ul className="breadcrumb">
                        <li><a href="#"><i className="fas fa-home"></i> Home</a></li>
                        <li><a href="#">Pages</a></li>
                        <li className="active">404</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- End Breadcrumb --> */}

    {/* <!-- Start 404 ============================================= --> */}
    <div className="error-page-area default-padding">
        <div className="container">
            <div className="row align-center">
                <div className="col-lg-6 thumb">
                    <img src="assets/img/illustration/404.png" alt="Cimeza Africa"/>
                </div>
                <div className="col-lg-6">
                    <div className="error-box">
                        <h2>Sorry Page Was Not Found!</h2>
                        <p>
                            Household shameless incommode at no objection behaviour. Especially do at he possession insensible sympathize boisterous.
                        </p>
                        <NavLink className="btn circle btn-md btn-gradient" to="/">Back to home</ NavLink>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- End 404 --> */}
        </>
    );
}

export default error;