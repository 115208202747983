import React from "react";
import { NavLink } from "react-router-dom";
import "../App.css";
function Events() {
  return (
    <>
      <div class="container custom_container">
        <div class="card borderr-none">
          <div class="row ">
            <div class="col-md-5 ">
              <img
                src="https://cdn.pixabay.com/photo/2022/01/10/04/37/event-6927353__480.jpg"
                className="service-picha" alt="Cimeza Africa"
              />
            </div>
            <div class="col-md-7 ">
              <div class="card-block custom_padding">
                <h4 class="card-title fw-bold custom-card-title">Events</h4>
                <p class="card-text">
                  We bring creativity and reliability to events planning across
                  East Africa with end to end event design, planning and
                  coordination, we make your brand shine. From conferences and
                  workshops to product launches, company parties and award
                  ceremonies, we take the hassle away and deliver memorable
                  experiences on message, on budget and on time. World-class
                  brands trust Cimeza Africa Events for peace of mind. Our
                  clients look for an event agency that can do it all; creating
                  a theme, putting together a team of suppliers and making it
                  happen. They demand quality and originality, within budget.
                </p>
                <br />

                <a className="btn circle btn-md custom-blue text-white fw-bold">
                  <NavLink to="/Contact">
                    {" "}
                    <span className="text-white">Request this Service</span>
                  </NavLink>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Events;
