/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import {NavLink} from "react-router-dom";
import "./product.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GetSignleProduct from "../redux/Services/FetchProduct.service";
import { image_url } from "../helpers/config";

const ProductDetails = () => {
  const { productId } = useParams();
  const product = useSelector((state) => state.product.product);
  console.log("Our Product:", product);

  // const {image, name, price, category, description } = product;
  const dispatch = useDispatch();
 
  useEffect(() => {
   dispatch(GetSignleProduct(productId));
    // return () => {
    //   dispatch(removeSelectedProduct());
    // };
  }, [dispatch, productId]);
  return (
    <div className="container">
      {product ? product.map((prod) => (
        <div className="card mb-3" style={{border: 'none'}} >
        <div class="row g-0">
          <div class="col-md-4">
           
            <img className="img-fluid rounded-start" src={`${image_url}${prod.image}`} />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title">{prod.name}</h5>
              <h3>{prod.category}</h3>
              {/* <h2>Ksh: {prod.price}</h2> */}
              <p>{prod.description}</p>
              <div className="btn btn-warning text-white"><NavLink to="/ProductForm" >Get Quote <i class="fa fa-cart-shopping"></i></NavLink></div>
            </div>
          </div>
        </div>
      </div>
      )): <div>...Loading</div> }
    </div>
  );
};

export default ProductDetails;
