import React, { useState, useEffect } from "react";
import {NavLink} from "react-router-dom";
import axios from "axios";

import { image_url } from "../helpers/config";
import { Base_url } from "../helpers/config";

function DataFetching() {
  let [products, setproducts] = useState([]);
  useEffect(() => {
    axios
      .get(`${Base_url}/products`)
      .then((response) => setproducts(response.data));
  }, []);

  return (
    <>
   <div class="row row-cols-1 row-cols-sm-4 g-4">
    {products.map((product)=>{
      const { id, name, image, price, category,description } = product;
      return(
      <div class="col">
      <div className="card" style={{width: "80%"}}>
        <img src={`${image_url}${image}`} className="card-img-top" alt="cimeza africa" />
        <div className="card-body" key={id}>
          <h5 className="card-title">{name}</h5>
          <div>
          <p className="card-text">{description}</p>
          </div>
          <p className="card-text">Category:{category}</p>
          <p className="card-text">price:{price}</p>
          <NavLink to={`/product/${id}`}><a href='cart' className="btn btn-sm btn-success">Add to Cart</a></NavLink>
        </div>
      </div>
  </div>
  

      );
    })}
     </div> 
    </>
  );
}

export default DataFetching;
