import react from "react";
import {Helmet} from "react-helmet-async"
const Projects = () => {
    <Helmet>
   <title>Cimeza Africa</title>
   <meta name="description" 
   content="Cimeza Africa SEO & Digital Marketing Agency, social media, branding ,web design and mobile application development, events"
   data-rh="true"/>

  <link rel="canonical" href="https://www.cimeza-africa.com/" />
  <link rel="alternate" href="https://www.cimeza-africa.com/" hreflang="en-eg" />
  <meta property="og:title" content="Cimeza Africa the Digital Marketing Agency social media, branding web design&amp; events | cimeza" />
  <meta property="og:description"
  content="Cimeza Africa, SEO & Digital Marketing Agency, social media, branding ,web design and mobile application development, events" />
  <meta property="og:url" content="https://www.cimeza-africa.com/" />
  <meta property="og:site_name" content="Cimeza Africa" />
<link rel="canonical"  href="/projects"/>
        
</Helmet> 
    return (
        
        <>
            {/* <!-- Start Breadcrumb ============================================= --> */}
            <div className="breadcrumb-area bg-gradient text-center">
                {/* <!-- Fixed BG --> */}
                <div className="fixed-bg" ></div>
                {/* <!-- Fixed BG --> */}
                <div className="container bredcrump-bg">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <h1>Projects</h1>
                            <ul className="breadcrumb">
                                <li><a href="/"><i className="fas fa-home"></i> Home</a></li>
                                
                                <li className="active">projects</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- End Breadcrumb --> */}

            {/* <!-- Start Projectss============================================= --> */}
            <div className="case-studies-area circle-shape-right custom-padding bottom-less">
                <div className="container">
                    <div className="case-items">
                        <div className="row">
                            {/* <!-- Single Item --> */}
                            <div className="single-item col-lg-4 col-md-6">
                                <div className="item wow fadeInUp" data-wow-delay="500ms">
                                    <div className="thumb">
                                        <img src="assets/img/case/1.jpg" alt="Cimeza Africa" />
                                        <div className="overlay">
                                            <a href="#"><i className="fas fa-link"></i></a>
                                        </div>
                                    </div>
                                    <div className="info">
                                        <div className="cats">
                                            <p>
                                                Sales, Web
                                            </p>
                                        </div>
                                        <h5>
                                            <a href="#">Community Engagement</a>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- End Single Item --> */}
                            {/* <!-- Single Item --> */}
                            <div className="single-item col-lg-4 col-md-6">
                                <div className="item wow fadeInUp" data-wow-delay="600ms">
                                    <div className="thumb">
                                        <img src="assets/img/case/2.jpg" alt="Cimeza Africa" />
                                        <div className="overlay">
                                            <a href="#"><i className="fas fa-link"></i></a>
                                        </div>
                                    </div>
                                    <div className="info">
                                        <div className="cats">
                                            <p>
                                                Marketing
                                            </p>
                                        </div>
                                        <h5>
                                            <a href="#">Income growth</a>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- End Single Item --> */}
                            {/* <!-- Single Item --> */}
                            <div className="single-item col-lg-4 col-md-6">
                                <div className="item wow fadeInUp" data-wow-delay="700ms">
                                    <div className="thumb">
                                        <img src="assets/img/case/3.jpg" alt="Cimeza Africa"/>
                                        <div className="overlay">
                                            <a href="#"><i className="fas fa-link"></i></a>
                                        </div>
                                    </div>
                                    <div className="info">
                                        <div className="cats">
                                            <p>
                                                SEO, Optimize
                                            </p>
                                        </div>
                                        <h5>
                                            <a href="#">Traffic Management</a>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- End Single Item --> */}
                            {/* <!-- Single Item --> */}
                            <div className="single-item col-lg-4 col-md-6">
                                <div className="item wow fadeInUp" data-wow-delay="500ms">
                                    <div className="thumb">
                                        <img src="assets/img/case/4.jpg" alt="Cimeza Africa" />
                                        <div className="overlay">
                                            <a href="#"><i className="fas fa-link"></i></a>
                                        </div>
                                    </div>
                                    <div className="info">
                                        <div className="cats">
                                            <p>
                                                Sales, Web
                                            </p>
                                        </div>
                                        <h5>
                                            <a href="#">Community Engagement</a>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- End Single Item --> */}
                            {/* <!-- Single Item --> */}
                            <div className="single-item col-lg-4 col-md-6">
                                <div className="item wow fadeInUp" data-wow-delay="600ms">
                                    <div className="thumb">
                                        <img src="assets/img/case/5.jpg" alt="Cimeza Africa" />
                                        <div className="overlay">
                                            <a href="#"><i className="fas fa-link"></i></a>
                                        </div>
                                    </div>
                                    <div className="info">
                                        <div className="cats">
                                            <p>
                                                Marketing
                                            </p>
                                        </div>
                                        <h5>
                                            <a href="#">Income growth</a>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- End Single Item --> */}
                            {/* <!-- Single Item --> */}
                            <div className="single-item col-lg-4 col-md-6">
                                <div className="item wow fadeInUp" data-wow-delay="700ms">
                                    <div className="thumb">
                                        <img src="assets/img/case/6.jpg" alt="Cimeza Africa" />
                                        <div className="overlay">
                                            <a href="#"><i className="fas fa-link"></i></a>
                                        </div>
                                    </div>
                                    <div className="info">
                                        <div className="cats">
                                            <p>
                                                SEO, Optimize
                                            </p>
                                        </div>
                                        <h5>
                                            <a href="#">Traffic Management</a>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- End Single Item --> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- End Projects --> */}
        </>
    );
}
export default Projects;