import react from "react";
import {NavLink} from "react-router-dom";
import Modal from "../components/Modal";
import {Helmet} from "react-helmet-async";


function header() {
    <Helmet>
   <title>Cimeza Africa</title>
   <meta name="description" 
   content="Cimeza Africa SEO & Digital Marketing Agency, social media, branding ,web design and mobile application development, events"
   data-rh="true"/>

  <link rel="canonical" href="https://www.cimeza-africa.com/" />
  <link rel="alternate" href="https://www.cimeza-africa.com/" hreflang="en-eg" />
  <meta property="og:title" content="Cimeza Africa the Digital Marketing Agency social media, branding web design&amp; events | cimeza" />
  <meta property="og:description"
  content="Cimeza Africa, SEO & Digital Marketing Agency, social media, branding ,web design and mobile application development, events" />
  <meta property="og:url" content="https://www.cimeza-africa.com/" />
  <meta property="og:site_name" content="Cimeza Africa" />
<link rel="canonical"  href=""/>
        
</Helmet> 
    return (
        <>


            {/* //    <!-- Preloader Start --> */}
            <div id="site-preloader" className="site-preloader">
                <div className="loader-wrap">
                    <div className="ring">
                        <span></span>
                    </div>
                    <h2>Cimeza Africa</h2>
                </div>
            </div>
            {/* // <!-- Preloader Ends -->

            // <!-- Start Header Top 
            // ============================================= --> */}
            <div className="top-bar-area custom-blue text-light">
                <div className="container">
                    <div className="row align-center">
                        <div className="col-lg-7 address-info">
                            <div className="info box">
                                <ul>
                                    <li>
                                        <i className="fas fa-map-marker-alt"></i> Nairobi Lenana Road, Kilimani
                                    </li>
                                    <li>
                                        <i className="fas fa-envelope-open"></i>info@cimeza-africa.com
                                    </li>
                                    <li>
                                       
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-5 text-right button"> 
                            <div className="item-flex">
                                <NavLink className="button" to="/Contact"><i className="fas fa-chart-line"></i> Talk to us</NavLink>
                                <NavLink className="button" to="/Contact"><i class="fa-solid fa-phone"></i> +254793612579</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* // <!-- End Header Top -->

            // <!-- Header 
            // ============================================= --> */}
            <header id="home">

                {/* <!-- Start Navigation --> */}
                <nav className="navbar navbar-default navbar-sticky dark attr-border bootsnav">

                    {/* <!-- Start Top Search --> */}
                    <div className="container">
                        <div className="row">
                            <div className="top-search">
                                <div className="input-group">
                                    <form action="#">
                                        <input type="text" name="text" className="form-control" placeholder="Search" />
                                        <button type="submit">
                                            <i className="ti-search"></i>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End Top Search --> */}

                    <div className="container">

                        {/* <!-- Start Atribute Navigation --> */}
                        <div className="attr-nav">
                            <ul>
                                <li className="search"><a href="#"><i className="fas fa-search"></i></a></li>
                            </ul>
                        </div>
                        {/* <!-- End Atribute Navigation -->

                <!-- Start Header Navigation --> */}
                        <div className="navbar-header">
                            <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#navbar-menu">
                                <i className="fa fa-bars"></i>
                            </button>
                            <NavLink className="navbar-brand" to="/">
                                <img src="./assets/logos/fulllogo-aside.png" className="logo" alt="Cimeza Africa" />
                            </NavLink>
                        </div>
                        {/* <!-- End Header Navigation -->

                <!-- Collect the nav links, forms, and other content for toggling --> */}
                        <div className="collapse navbar-collapse" id="navbar-menu">
                            <ul className="nav navbar-nav navbar-right" data-in="fadeInDown" data-out="fadeOutUp">
                                {/* <li className="dropdown">
                                    <NavLink to="/" className=" active" data-toggle="dropdown" >Home</NavLink>
                                </li> */}
                                <li className="dropdown">
                                    <a href="/" className="dropdown-toggle" data-toggle="dropdown" >Home</a>
                                    <ul className="dropdown-menu">
                                   
                                        <li className="text-center"><NavLink to="/process">About Cimeza Africa</NavLink></li>
                                       
                                        <li><NavLink to="/Projects">Our Projects</NavLink></li>
                                        {/* /faq */}
                                        <li><NavLink to="/faq">Faq</NavLink></li>
                                    </ul>
                                </li> 
                                <li className="dropdown">
                                {/* data-toggle="modal" data-target="#modal-fullscreen-xl" */}
                                    <NavLink to="/Services">Digital</NavLink>
                                    <ul className="dropdown-menu">
                                        <li> <NavLink to="/social" >Social Media Management</NavLink></li>
                                        <li> <NavLink to="/seo" >Search Engine Optimization</NavLink></li>
                                        <li> <NavLink to="/Services" >View All services</NavLink></li>
                                       
                                        {/* <li><a href="#" data-toggle="modal" data-target="#modal-fullscreen-xl">View All services </a></li> */}
                                    </ul>
                                </li>
                        
                                {/* <li className="dropdown">
                                    <NavLink  className="dropdown-toggle"   data-toggle="modal" data-target="#modal-fullscreen-xl" >Marketing</NavLink> */}
                                    <li className="dropdown">
                                
                                <NavLink to="/strategy">Marketing</NavLink>
                                    <ul className="dropdown-menu">
                                    <li> <NavLink to="./strategy">Marketing & Strategy</NavLink></li>
                                      <li> <NavLink to="./brandingSignage">Branding & Signages</NavLink></li>
                                      <li> <NavLink to="./catalogues">Corprate Gifts & Apparel</NavLink></li>
                                      <li> <NavLink to="./events">Events Management</NavLink></li> 
                                      
                                        
                                    </ul>
                                </li>

                                <li className="dropdown">
                                {/* data-toggle="modal" data-target="#modal-fullscreen-xl" */}
                                    <NavLink to="/creative">Creative</NavLink>
                                    <ul className="dropdown-menu">
                                       
                                       <li><NavLink to="/creative">Banners</NavLink></li>
                                       <li><NavLink to="/graphic">Graphic Design</NavLink></li>
                                       <li><NavLink to="/ShortVideos">Gifs</NavLink></li>
                                      <li><NavLink to="/ShortVideos">Short Social Media Videos</NavLink></li>
                                </ul>
                                </li>
                                <li><NavLink to="/app_dev">Web & app Dev</NavLink></li>
                                <li><NavLink to="/catalogues">Catalogues</NavLink></li>
                                {/* <LocalMallIcon /> */}
                                {/* <i class="fa-sharp fa-solid fa-bag-shopping text-warning"></i> */}
                                {/* <li><NavLink to="/ProductPage">Shop</NavLink></li> */}
                                <li>
                                    <NavLink to="/Contact">Contact</NavLink>
                                </li>
                            </ul>
                        </div>
                        {/* <!-- /.navbar-collapse --> */}
                    </div>

                </nav>
                {/* <!-- End Navigation --> */}

            </header>
            {/* <!-- End Header --> */}

            <Modal/>

        </>


    );
}

export default header;
