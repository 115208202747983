import React from "react";
import { NavLink } from "react-router-dom";
import "../App.css";
function Creative() {
  return (
    <>
      <div class="container custom_container">
        <div class="card borderr-none">
          <div class="row ">
            <div class="col-md-5 ">
              <img
                src="https://cdn-icons-png.flaticon.com/512/3234/3234960.png"
                className="service-picha"
              />
            </div>
            <div class="col-md-7 ">
              <div class="card-block custom_padding">
                <h4 class="card-title custom-card-title">
                  Let's Visualize Your Thinking Through Design
                </h4>
                <p class="card-text">
                  We offer one-stop solutions We work with specific goals in
                  mind, and deliver content that elevates clients brand
                  messaging. Talk to us for Graphics design, Photography, short
                  videos, promotional items, Banners, Corporate gifts & Apparel.
                </p>
                <br />
                <br />

                <a className="btn circle btn-md custom-blue text-white fw-bold">
                  <NavLink to="/Contact">
                    {" "}
                    <span className="text-white">Request this Service</span>
                  </NavLink>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Creative;
