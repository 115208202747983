import react from "react";
import {NavLink} from "react-router-dom"
import {Helmet} from "react-helmet-async"

function process() {
    <Helmet>
    <title>Cimeza Africa</title>
    <meta name="description" 
    content="Cimeza Africa SEO & Digital Marketing Agency, social media, branding ,web design and mobile application development, events"
    data-rh="true"/>
 
   <link rel="canonical" href="https://www.cimeza-africa.com/" />
   <link rel="alternate" href="https://www.cimeza-africa.com/" hreflang="en-eg" />
   <meta property="og:title" content="Cimeza Africa the Digital Marketing Agency social media, branding web design&amp; events | cimeza" />
   <meta property="og:description"
   content="Cimeza Africa, SEO & Digital Marketing Agency, social media, branding ,web design and mobile application development, events" />
   <meta property="og:url" content="https://www.cimeza-africa.com/" />
   <meta property="og:site_name" content="Cimeza Africa" />
 <link rel="canonical"  href="/process"/>
         
 </Helmet> 
    return (
        <>

            {/* <!-- Start Breadcrumb ============================================= --> */}
            <div className="breadcrumb-area bg-gradient text-center">
                {/* <!-- Fixed BG --> */}
                <div className="fixed-bg" ></div>
                {/* <!-- Fixed BG --> */}
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <h1>About Us</h1>
                            <ul className="breadcrumb">
                                <li><a href="#"><i className="fas fa-home"></i> Home</a></li>
                                <li><a href="#">Pages</a></li>
                                <li className="active">Process</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- End Breadcrumb --> */}

            {/* <!-- Start Tabs ============================================= --> */}
            <div className="tabs-content-area custom-padding">
                <div className="container">
                    <div className="center-tabs">
                        <div className="row">
                            <div className="col-lg-4">
                                <ul id="tabs" className="nav nav-tabs">
                                    <li className="nav-item">
                                        <a href="#" data-target="#tab1" data-toggle="tab" className="active nav-link">Our Mission</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#" data-target="#tab2" data-toggle="tab" className="nav-link">Our Vission</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#" data-target="#tab3" data-toggle="tab" className="nav-link">Success Rate</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-8">
                                <div id="tabsContent" className="tab-content wow fadeInUp" data-wow-delay="0.5s">
                                    <div id="tab1" className="tab-pane fade active show">
                                        <div className="row align-center">
                                            <div className="col-lg-12 info">
                                                <h5>Our Mission</h5>
                                                <h2> Creativity & Solution oriented</h2>
                                                <p>
                                                We are an award-winning agency with a mission to create meaningful brand experiences and connections by helping brands tell their story. 
                                               We partner with brands to bring their stories to life through marketing strategies, 
                                               creative design, digital marketing, event management, and branded merchandise. 
                                                </p>
                                                <a className="btn-simple" href="#"><i className="fas fa-angle-right"></i> Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="tab2" className="tab-pane fade">
                                        <div className="row align-center">
                                            <div className="col-lg-12 info">
                                                <h5>Our Vission</h5>
                                                <h3>Collect Ongoing Big Data <br /> Design And Strategy</h3>
                                                <p>
                                                "To be an exceptional digital marketing agency driving exceptional value to its clients, employees,
                                                 community and other stakeholders."
                                                </p>
                                                <a className="btn-simple" href="#"><i className="fas fa-angle-right"></i> Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="tab3" className="tab-pane fade">
                                        <div className="row align-center">
                                            <div className="col-lg-12 info">
                                          
                                                <h5> Our Success Rate</h5>
                                                
                                                <p>
                                                Cimeza Africa is an award winning digital marketing agency based in Nairobi, Kenya. We bring our passion,
                                                 creativity and clearly defined processes to work and let that transform into innovative digital campaigns, 
                                                 results focused and visually appealing website designs, search campaigns, display campaigns,
                                                 social media campaigns, branding and more. We find our growth in our client's growth. We hold ourselves 
                                                 to the highest standards of service levels and creative excellence.
                                                </p>
                                                <a className="btn-simple" href="#"><i className="fas fa-angle-right"></i> Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- End Tabs --> */}

            {/* <!-- Start Process Area  ============================================= --> */}
            <div className="process-area default-padding-bottom">
                {/* <!-- Fixed Shape  --> */}
                <div className="shape-left-top shape opacity-default">
                    <img src="assets/img/shape/4.png" alt="Cimeza Africa" />
                </div>
                {/* <!-- Fixed Shape  --> */}
                <div className="container">
                    <div className="process-items">
                        <div className="row align-center">

                            <div className="col-lg-6 thumb wow fadeInLeft">
                                <img src="assets/img/illustration/3.png" alt="Cimeza Africa" />
                            </div>

                            <div className="col-lg-6 info wow fadeInUp">
                            <h2>We love What we Do  <strong>And Helping others Succeed</strong></h2>
                                <div className="content">
                                    <div className="item">
                                        <div className="icon">
                                            <i className="fas fa-angle-right"></i>
                                        </div>
                                        <h4><strong>01.</strong> Research</h4>
                                        <p>
                                        We keep an eye on trends and tendencies ,Identifying new target audiences and Finding approaches for new marketing campaigns
                                        </p>
                                    </div>
                                    <div className="item">
                                        <div className="icon">
                                            <i className="fas fa-angle-right"></i>
                                        </div>
                                        <h4><strong>02.</strong> Data Collection</h4>
                                        <p>
                                            We focus on collecting relevant data for our clients
                                        </p>
                                    </div>
                                    <div className="item">
                                        <div className="icon">
                                            <i className="fas fa-angle-right"></i>
                                        </div>
                                        <h4><strong>03.</strong> Targeting</h4>
                                        <p>
                                           Audience Targeting Strategies from Digital Marketing Experts.
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- End Process Area --> */}
        </>
    );
}

export default process;