import React from "react";
import {NavLink} from "react-router-dom";
import '../App.css';
function Social() {
  return (
    <>
      <div class="container custom_container">
        <div class="card borderr-none">
          <div class="row ">
          <div class="col-md-5 ">
            
              <img
                src="https://cdn.pixabay.com/photo/2017/08/16/11/38/tree-2647471_1280.png"
                className="service-picha"
              />
            </div>
            <div class="col-md-7 ">
              <div class="card-block custom_padding">
                <h4 class="card-title">SOCIAL MEDIA & ADVERTISING</h4>
                <p class="card-text">
                  Our passion for content marketing, community management and
                  hash-tagging is second to none. Facebook, Instagram or Google
                  Search and Display – you name it, we’ve got it covered,
                  generating content and leveraging it via organic and paid
                  means. Through careful content creation and planning, we build
                  brand affinity one post at a time.
                </p>
                <br/><br/>
                <a className="btn circle btn-md custom-blue text-white fw-bold">
                 <NavLink to="/Contact"> <span className="text-white">Request this Service</span></NavLink>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Social;
