import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Base_url } from "../../helpers/config";

const GetSignleProduct = createAsyncThunk("Products/GetProduct", async (id) => {
  try {
    const response = await axios.get(`${Base_url}/products/${id}`);
    const data = await response.data;
    if (!data) {
      throw new Error(response.statusText);
    }
    return data;
  } catch (error) {
    console.log(error);
  }
});

export default GetSignleProduct;
