import react from "react";
import {NavLink} from "react-router-dom"
import {Helmet} from "react-helmet-async"

function home() {
<Helmet>
   <title>Cimeza Africa</title>
   <meta name="description" 
   content="Cimeza Africa SEO & Digital Marketing Agency, social media, branding ,web design and mobile application development, events"
   data-rh="true"/>

  <link rel="canonical" href="https://www.cimeza-africa.com/" />
  <link rel="alternate" href="https://www.cimeza-africa.com/" hreflang="en-eg" />
  <meta property="og:title" content="Cimeza Africa the Digital Marketing Agency social media, branding web design&amp; events | cimeza" />
  <meta property="og:description"
  content="Cimeza Africa, SEO & Digital Marketing Agency, social media, branding ,web design and mobile application development, events" />
  <meta property="og:url" content="https://www.cimeza-africa.com/" />
  <meta property="og:site_name" content="Cimeza Africa" />
<link rel="canonical"  href="/home"/>
        
</Helmet> 
    return <>
    

            {/* <!-- Start Banner 
           ========================================== --> */}
            <div className="banner-area auto-height bg-cover-bottom">
                <div className="container">
                    <div className="double-items">
                        <div className="row align-center">
                            <div className="col-lg-6 col-md-6 info my-tagline">
                                <h2 className="wow fadeInDown" data-wow-duration="1s">Cimeza Africa <span className=""> The Digital Marketing</span> Agency</h2>
                                <p className="wow fadeInLeft" data-wow-duration="1.5s">
                                We conceptualize and create using insights that deliver strategic and impactful digital services results
                                </p>
                                
                               </div>
                            <div className="col-lg-6 col-md-6 thumb width-110 wow fadeInRight" data-wow-duration="1s">
                                <img src="assets/img/illustration/8.png" alt="Cimeza Africa"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- End Banner -->

            <!-- Start Services Area
           ============================================= --> */}
            <div className="services-area default-padding bottom-less">
                {/* <!-- Fixed Shape  --> */}
                <div className="shape-bottom">
                    <img src="assets/img/shape/12.png" alt="Cimeza Africa"/>
                </div>
                {/* <!-- Fixed Shape  --> */}
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="site-heading text-center">
                                <h5>What we do</h5>
                                <h2>
                                    Let s Check our popular Digital <br/>Services & solutions
                                </h2>
                                <div className="heading-divider"></div>
                            </div>
                        </div>
                    </div>
                    <div className="services-items text-center">
                        <div className="row">
                            {/* <!-- Single Item --> */}
                            <div className="single-item col-lg-4 col-md-6">
                                <div className="item wow fadeInUp" data-wow-delay="500ms">
                                    <div className="icon">
                                    <i className="flaticon-ppc"></i>
                                    </div>
                                    <div className="info">
                                        <h4>
                                            <a href="#" className="text-warning">Mobile App and Web development </a>
                                        </h4>
                                        <p>
                                        Fullstack responsive web and mobile apps
                                        </p>
                                        <a ><i className="fas fa-arrow-right"></i> <NavLink to="/app_dev">More</NavLink> </a>

                                        
                                    </div>
                                </div>
                            </div>
                            {/* <!-- End Single Item -->
                            <!-- Single Item --> */}
                            <div className="single-item col-lg-4 col-md-6">
                                <div className="item wow fadeInUp" data-wow-delay="600ms">
                                    <div className="icon">
                                        <i className="flaticon-keyword-1"></i>
                                    </div>
                                    <div className="info">
                                        <h4>
                                            <a href="#" className="text-warning">Creative Design </a>
                                        </h4>
                                        <p>
                                        Preparation of all digital assets such as banners, gifs, and short social media videos.
                                        </p>
                                        <a ><i className="fas fa-arrow-right"></i> <NavLink to="/creative">More</NavLink> </a>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- End Single Item -->
                            <!-- Single Item --> */}
                            <div className="single-item col-lg-4 col-md-6">
                                <div className="item wow fadeInUp" data-wow-delay="700ms">
                                    <div className="icon">
                                        <i className="flaticon-marketing"></i>
                                    </div>
                                    <div className="info">
                                        <h4>
                                            <a href="#" className="text-warning">Digital Marketing Strategy</a>
                                        </h4>
                                        <p>
                                        Formulation of key go-to-market plans for your business
                                       
                                        </p>
                                        <br/>
                                        <span className="bg-white text-white"> Formulation </span>
                                        <a ><i className="fas fa-arrow-right"></i> <NavLink to="./strategy">More</NavLink> </a>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- End Single Item -->
                            <!-- Single Item --> */}
                            <div className="single-item col-lg-4 col-md-6">
                                <div className="item wow fadeInUp" data-wow-delay="500ms">
                                    <div className="icon">
                                        <i className="flaticon-bullhorn"></i>
                                    </div>
                                    <div className="info">
                                        <h4>
                                            <a href="#" className="text-warning">Social Media Management</a>
                                        </h4>
                                        <p className="">
                                        all-rounded brand management that covers regular updates and reputation management
                                        </p>
                                        <a ><i className="fas fa-arrow-right"></i> <NavLink to="/social">More</NavLink> </a>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- End Single Item -->
                            <!-- Single Item --> */}
                            <div className="single-item col-lg-4 col-md-6">
                                <div className="item wow fadeInUp" data-wow-delay="600ms">
                                    
                                    <img src="https://cdn-icons-png.flaticon.com/512/4358/4358450.png" class=" custom-card-img" alt="Cimeza Africa"/>
                                    
                                    <div className="info">
                                        <h4>
                                            <a href="#" className="text-warning">Events Management </a>
                                        </h4>
                                        <p>
                                        We bring freshness, creativity and reliability to corporate events planning and management
                                        </p>
                                        <a ><i className="fas fa-arrow-right"></i> <NavLink to="/events">More</NavLink> </a>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- End Single Item -->
                            <!-- Single Item --> */}
                            <div className="single-item col-lg-4 col-md-6">
                                <div className="item wow fadeInUp" data-wow-delay="700ms">
                                    <div className="icon">
                                        <i className="flaticon-rising"></i>
                                    </div>
                                    <div className="info">
                                        <h4>
                                            <a href="#" className="text-warning">Digital Catalogues </a>
                                        </h4>
                                        <p>
                                            We Offer a variety of latest Digital Catalogues <br/><br/>
                                        </p>
                                        <a ><i className="fas fa-arrow-right"></i> <NavLink to="./catalogues">More</NavLink> </a>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- End Single Item --> */}
                        </div>
                    </div>
                </div>
               <center ><a href="#" className="btn custom-blue text-center text-white" data-toggle="modal" data-target="#modal-fullscreen-xl">View all Services<i className="fas fa-arrow-right "></i></a></center>
            </div>
            {/* <!-- End Services Area -->

            <!-- Start Our Benefits 
    ============================================= --> */}
            <div className="benifits-area reverse default-padding">
                <div className="container">
                    <div className="row align-center">

                        <div className="col-lg-6 thumb wow fadeInLeft">
                            <img src="assets/img/illustration/44.png" alt="Cimeza Africa"/>
                        </div>

                        <div className="col-lg-6 info wow fadeInDown">
                            <h5 className="text-custom-blue">Why Choose us</h5>
                            <h2>Get our solutions <strong>On Click of button</strong></h2>
                            <p>
                            Our diverse team of experts is always looking for innovative new ways to solve our customers challenges.
                            and we We believe in keeping our word and delivering solutions on time, within budget and meeting your specifications
                            </p>
                            <ul>
                                <li>
                                    <h5>Global Reach</h5>
                                </li>
                                <li>
                                    <h5>Big Experience</h5>
                                </li>
                                <li>
                                    <h5>Convenience</h5>
                                </li>
                                <li>
                                    <h5>Team Strength</h5>
                                </li>
                            </ul>
                            <NavLink className="button" to="/Contact"> <a className="btn circle btn-md custom-blue text-white fw-bold">Get Started Now</a></NavLink>
                        </div>

                    </div>
                </div>
            </div>
            {/* <!-- End Our Benefits -->

            <!-- Start Test SEO
    ============================================= --> */}
            <div className="seo-form subscribe-area half-bg-gray default-padding-bottom text-center">
                <div className="container">
                    <div className="sebscribe-items wow fadeInUp">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2 form">
                                <h2>Check Your Website’s SEO Score <strong className="text-custom-blue">For Free with us!</strong></h2>
                                <form action="#">
                                    <div className="input">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <input className="form-control" placeholder="Web URL" type="url"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <input className="form-control" placeholder="Email Address" type="email"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="custom-blue" type="submit">Check</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- End Test SEO -->

            <!-- Start Clients
    ============================================= --> */}
            <div className="clients-area  default-padding-bottom mt-4">
                <div className="container">
                    <div className="row align-center">
                        <div className="col-lg-5 info">
                            <h2>We've Worked With Them, <strong className="text-custom-blue"> Now Trust Each</strong> Other</h2>
                        </div>
                        <div className="col-lg-7">
                            <div className="clients-carousel owl-carousel owl-theme">
                                <div className="item">
                                    <img src="assets/partners/amazon.png" alt="Cimeza Africa"/>
                                </div>
                                <div className="item">
                                <img src="assets/partners/sense-new.png" alt="Cimeza Africa" />
                                </div>
                                <div className="item">
                                <img src="assets/partners/safaricom.png" alt="Cimeza Africa"/>
                                </div>
                                <div className="item">
                                <img src="assets/partners/hygienia.png" alt="Cimeza Africa" />
                                </div>
                                <div className="item">
                                    <img src="assets/img/clients/5.png" alt="Cimeza Africa"/>
                                </div>
                               {/* other partners */}

                               <div className="item">
                                    <img src="assets/partners/ziada.png" alt="Cimeza Africa"/>
                                </div>
                                <div className="item">
                                    <img src="assets/partners/events-mit.png" alt="Cimeza Africa"/>
                                </div>

                                <div className="item">
                                    <img src="assets/partners/chilli-pop.png" alt="Cimeza Africa"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- End Clients -->

            <!-- Start Why Choose Us 
    ============================================= --> */}
            <div className="choose-us-area default-padding-bottom bg-gray">
                <div className="container">
                    <div className="row align-center">
                        <div className="col-lg-6 thumb wow fadeInUp">
                            <img src="assets/img/illustration/7.png" alt="Cimeza Africa"/>
                        </div>
                        <div className="col-lg-6 info wow fadeInDown">
                            <h2 className="text-custom-blue fw-bold">We Offer a Full Range of <br/> <strong>Digital Solutions & Services!</strong></h2>
                            <p>
                            Digital services and products are set to become increasingly significant for revenues for B2B technology companies.
                            </p>
                            <p>
                            Building on over 30 years of experience with sales effectiveness projects, we developed a Digital Commercial Excellence Self-Assessment tool for senior executives and sales managers to recognize their company’s potential for improvement regarding operating model, customer experience, and data & technology. Take our survey now!
                            </p>
                            <NavLink className="button" to="/Contact"> <a className="btn circle btn-md btn-gradient orange">Get in Touch with us</a></NavLink>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- End Why Choose Us -->

            

            <!-- Start Fun Factor Area
    ============================================= --> */}
            <div className="fun-factor-area bg-gray default-padding-bottom">
                <div className="container">
                    <div className="client-items text-center">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 item">
                                <div className="fun-fact">
                                    <div className="timer" data-to="687" data-speed="5000">687</div>
                                    <span className="medium">Projects Executed</span>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 item">
                                <div className="fun-fact">
                                    <div className="timer" data-to="2348" data-speed="5000">2348</div>
                                    <span className="medium">Data Analytics</span>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 item">
                                <div className="fun-fact">
                                    <div className="timer" data-to="450" data-speed="5000">450</div>
                                    <span className="medium">Data Management</span>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 item">
                                <div className="fun-fact">
                                    <div className="timer" data-to="1200" data-speed="5000">1200</div>
                                    <span className="medium">Satisfied Customers</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- End Fun Factor Area -->

            <!-- Start Process Area
    ============================================= --> */}
            <div className="process-area default-padding-bottom bg-gray">
                <div className="container">
                    <div className="process-items">
                        <div className="row align-center">

                            <div className="col-lg-6 thumb">
                                <img src="assets/img/illustration/3.png" alt="Cimeza Africa"/>
                            </div>

                            <div className="col-lg-6 info">
                                <h2>We love What we Do  <strong>And Helping others Succeed</strong></h2>
                                <div className="content">
                                    <div className="item">
                                        <div className="icon">
                                            <i className="fas fa-angle-right"></i>
                                        </div>
                                        <h4><strong>01.</strong> Research</h4>
                                        <p>
                                        We keep an eye on trends and tendencies ,Identifying new target audiences and Finding approaches for new marketing campaigns
                                        </p>
                                    </div>
                                    <div className="item">
                                        <div className="icon">
                                            <i className="fas fa-angle-right"></i>
                                        </div>
                                        <h4><strong>02.</strong> Data Collection</h4>
                                        <p>
                                            We focus on collecting relevant data for our clients
                                        </p>
                                    </div>
                                    <div className="item">
                                        <div className="icon">
                                            <i className="fas fa-angle-right"></i>
                                        </div>
                                        <h4><strong>03.</strong> Targeting</h4>
                                        <p>
                                           Audience Targeting Strategies from Digital Marketing Experts.
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* End Process Area  */}

             <div className="blog-area default-padding bottom-less bg-gray">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="site-heading text-center">
                                <h5>Latest Trends</h5>
                                <h2>Stay Up to date  with Cimeza Africa<br/> latest & popular Trends</h2>
                                <div className="heading-divider"></div>
                            </div>
                        </div>
                    </div>
                    <div className="blog-items content-less">
                        <div className="row">
                            {/* <!-- Single Item --> */}
                            <div className="single-item col-lg-4 col-md-6">
                                <div className="item wow fadeInUp" data-wow-delay="600ms">
                                    <div className="thumb">
                                        <a href="#"><img src="assets/img/blog/5.jpg" alt="Cimeza Africa"/></a>
                                    </div>
                                    <div className="info">
                                        <div className="tags">
                                            <a href="#">SEO</a>
                                            <a href="#">Analysis</a>
                                        </div>
                                        <div className="meta">
                                            <ul>
                                                <li><i className="fas fa-calendar-alt"></i> 18 Nov, 2022</li>
                                                <li>By <a href="#">Park Lee</a></li>
                                            </ul>
                                        </div>
                                        <h4>
                                            <a href="#">Enhace visibility of your website on the internet. </a>
                                        </h4>
                                        <a className="btn circle btn-theme border btn-sm" href="#">Read More <i className="fas fa-long-arrow-alt-right"></i></a>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- End Single Item -->
                            <!-- Single Item --> */}
                            <div className="single-item col-lg-4 col-md-6">
                                <div className="item wow fadeInUp" data-wow-delay="600ms">
                                    <div className="thumb">
                                        <a href="#"><img src="assets/img/blog/2.jpg" alt="Cimeza Africa"/></a>
                                    </div>
                                    <div className="info">
                                        <div className="tags">
                                            <a href="#">Enhance</a>
                                            <a href="#">your web application</a>
                                        </div>
                                        <div className="meta">
                                            <ul>
                                                <li><i className="fas fa-calendar-alt"></i> 05 Dec, 2021</li>
                                                <li>By <a href="#">Tyson</a></li>
                                            </ul>
                                        </div>
                                        <h4>
                                            <a href="#">Fully optimize your web applications.</a>
                                        </h4>
                                        <a className="btn circle btn-theme border btn-sm" href="#">Read More <i className="fas fa-long-arrow-alt-right"></i></a>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- End Single Item -->
                            <!-- Single Item --> */}
                            <div className="single-item col-lg-4 col-md-6">
                                <div className="item wow fadeInUp" data-wow-delay="600ms">
                                    <div className="thumb">
                                        <a href="#"><img src="assets/img/blog/3.jpg" alt="Cimeza Africa"/></a>
                                    </div>
                                    <div className="info">
                                        <div className="tags">
                                            <a href="#">Digital</a>
                                            <a href="#">Marketing</a>
                                        </div>
                                        <div className="meta">
                                            <ul>
                                                <li><i className="fas fa-calendar-alt"></i> 26 Sep, 2020</li>
                                                <li>BY <a href="#">Jay Baer</a></li>
                                            </ul>
                                        </div>
                                        <h4>
                                            <a href="#">Create awareness for your bussiness.</a>
                                        </h4>
                                        <a className="btn circle btn-theme border btn-sm" href="#">Read More <i className="fas fa-long-arrow-alt-right"></i></a>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- End Single Item --> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- End Blog Area --> */}

            
            </>
        
    
    
}

export default home;
