import React from 'react'
import Card from './Card'

function Cards(props) {
  return (
   <>
   <div className="container text-center ">
  < h3 className="text-center services-header">{props.componentHeader}</h3>
       <div className="card-deck">
      <Card cardtitle='Graphics Design' cardtext='Image is everything when it comes to business success. Our graphics design team are creative and capture our client’s interests through graphics.' />
      <Card cardtitle='CRM' cardtext='Customer Relationship Management is critical in the current digital landscape. It allows us to accurately target the consumers.' />
      <Card cardtitle='Email Marketing' cardtext='We offer most reliable and cost effective forms of communication. Whether you are looking at driving traffic to your website' />
      
 </div>
 </div>
</>
  )
}
export default Cards

