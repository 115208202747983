import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Base_url } from "../../helpers/config";

const CreateProduct = createAsyncThunk(
  "Products/CreateProduct",
  async (product) => {
    try {
      const response = axios.post(`${Base_url}/product/add`, product);
      const data = await response.data;
      if (!data) {
        throw new Error(response.statusText);
      }
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export default CreateProduct;
