/* eslint-disable jsx-a11y/anchor-is-valid */
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
const Footer = () => {
  <Helmet>
    <title>Cimeza Africa</title>
    <meta
      name="description"
      content="Cimeza Africa SEO & Digital Marketing Agency, social media, branding ,web design and mobile application development, events"
      data-rh="true"
    />

    <link rel="canonical" href="https://www.cimeza-africa.com/" />
    <link
      rel="alternate"
      href="https://www.cimeza-africa.com/"
      hreflang="en-eg"
    />
    <meta
      property="og:title"
      content="Cimeza Africa the Digital Marketing Agency social media, branding web design&amp; events | cimeza"
    />
    <meta
      property="og:description"
      content="Cimeza Africa, SEO & Digital Marketing Agency, social media, branding ,web design and mobile application development, events"
    />
    <meta property="og:url" content="https://www.cimeza-africa.com/" />
    <meta property="og:site_name" content="Cimeza Africa" />
    <link rel="canonical" href="/" />
  </Helmet>;
  return (
    <>
      <footer className="footer-custom-style text-light">
        <div className="svg-shape">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="gray"
            preserveAspectRatio="none"
            viewBox="0 0 1070 52"
          >
            <path d="M0,0S247,91,505,32c261.17-59.72,565-13,565-13V0Z"></path>
          </svg>
        </div>
        <div className="container">
          <div className="f-items custom-padding">
            <div className="row">
              <div className="col-lg-4 col-md-6 item">
                <div className="f-item about">
                  <img
                    src="assets/logos/cimeza-logo-clear-bg.png"
                    alt="cimeza-africa"
                    className="foooter-logo"
                  />
                  <div className="address">
                    <ul>
                      <li>
                        <div className="icon">
                          <i className="flaticon-email"></i>
                        </div>
                        <div className="info">
                          <h5>Email:</h5>
                          <span>info@cimeza-africa.com</span>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <i className="flaticon-call"></i>
                        </div>
                        <div className="info">
                          <h5>Phone:</h5>
                          <span>+254110496946</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="social-media">
                  <a
                    href="https://www.instagram.com/cimeza_africa/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <i className="fa-brands fa-instagram fa-2x"></i>
                  </a>
                  <a href="/" target="_blank" rel="noopener noreferrer">
                    {" "}
                    <i className="fa-brands fa-twitter fa-2x"></i>
                  </a>
                  <a href="#" target="_blank" rel="noopener noreferrer">
                    {" "}
                    <i className="fa-brands fa-whatsapp fa-2x"></i>
                  </a>
                  <a
                    href="https://www.facebook.com/profile.php?id=100086518749016"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <i className="fa-brands fa-facebook fa-2x"></i>
                  </a>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 item">
                <div className="f-item link">
                  <h4 className="widget-title">Cimeza Africa</h4>
                  <ul>
                    <li>
                      <NavLink to="/social">Social Media</NavLink>
                    </li>
                    <li>
                      <NavLink to="/process">About Us</NavLink>
                    </li>
                    <li>
                      <NavLink to="/catalogues">Catalogues</NavLink>
                    </li>
                    <li>
                      <NavLink to="/seo">SEO</NavLink>
                    </li>
                    <li>
                      <NavLink to="/projects">Projects</NavLink>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-2 col-md-6 item">
                <div className="f-item link">
                  <h4 className="widget-title">Navigation</h4>
                  <ul>
                    <li>
                      <li>
                        <NavLink to="/contact">Contact</NavLink>
                      </li>

                      <NavLink
                        data-toggle="modal"
                        data-target="#modal-fullscreen-xl"
                      >
                        Creative
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        data-toggle="modal"
                        data-target="#modal-fullscreen-xl"
                      >
                        Marketing
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/app_dev">Web & App</NavLink>
                    </li>
                    <li>
                      <NavLink
                        data-toggle="modal"
                        data-target="#modal-fullscreen-xl"
                      >
                        Services
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 item">
                <div className="f-item recent-post">
                  <h4 className="widget-title">Latest News</h4>
                  <div className="item footer-custom-style">
                    <a href="#" className="footer-custom-style">
                      We conceptualize and create using insights that deliver
                      strategic and impactful marketing results.
                    </a>
                  </div>
                  <div className="item custom-font">
                    <a href="#" className="footer-custom-style">
                      " exceptional digital marketing agency driving exceptional
                      value to its clients, employees, community and other
                      stakeholders "{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Start Footer Bottom --> */}
        <div className="footer-bottom bg-white fw-bold">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <p className="text-dark fw-bold display-5">
                  Copyright &copy; 2022. Developed by{" "}
                  <a href="https://brian-nyachae.netlify.app/" className="text-dark fw-bold">
                    |Cimeza software engineering team|
                  </a>
                </p>
              </div>
              <div className="col-md-6 text-right link">
                <ul>
                  <li>
                    <a href="#" className="text-dark fw-bold">
                      Terms
                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-dark fw-bold">
                      Privacy
                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-dark fw-bold">
                      Support
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- End Footer Bottom --> */}
      </footer>
    </>
  );
};

export default Footer;
