export const initialState = {
  products: [],
  status: "",
  error: "",
  product: [],
};

export const productState = {
  name : "",
  description: "",
  price: "",
  product_type: "",
  discount: "",
  units: "",
  category: "",
}

export const newProductState = {
  newproduct: [],
  status: "",
  error: ""
}
