import React from "react";
import {NavLink} from "react-router-dom";
import "../App.css";
function Seo() {
  return (
    <>
      <div class="container custom_container">
        <div class="card borderr-none">
          <div class="row ">
            <div class="col-md-5 ">
              <img
                src="https://cdn.pixabay.com/photo/2017/01/16/20/22/bubbles-1985153_1280.png"
                className="service-picha"
              />
            </div>
            <div class="col-md-7 ">
              <div class="card-block custom_padding">
                <h4 class="card-title fw-bold custom-card-title">Search Engine Optimization</h4>
                <p class="card-text">
                 Cimeza Africa offers SEO solution for businesses on both the local and national levels. Whether you
                  need a small SEO campaign or a complex multi-city campaign, we
                  can help. National SEO is a long-term investment that can
                  separate your business from the competitors. Although similar
                  to local SEO, National SEO focuses primarily on ranking for
                  broad keyword terms rather than ranking for specific
                  geographical terms. NATIONAL SEOIn today’s tech-driven
                  society, businesses need to focus more on their online
                  presence now more than ever. The amount of companies on a
                  national level using SEO is much higher than that of local
                  businesses, making it a very competitive market. National SEO
                  strategies will allow your business to transform how it
                  competes in the market as it can extend your reach across the
                  country and capture your ideal client base, resulting in
                  positive ROI. Our National SEO strategies will optimize
                  various facets of your website to ensure positive search
                  engine visibility
                </p>
                <br />
                
                <a className="btn circle btn-md custom-blue text-white fw-bold">
                 <NavLink to="/Contact"> <span className="text-white">Request this Service</span></NavLink>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Seo;
