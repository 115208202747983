import React from "react";
import { NavLink } from "react-router-dom";
import "../App.css";
function ShortVideos() {
  return (
    <>
      <div class="container custom_container">
        <div class="card borderr-none">
          <div class="row ">
            <div class="col-md-5 ">
            <img
                src="https://cdn.pixabay.com/photo/2017/03/25/04/00/youtube-2172750__480.png"
                className="service-picha"
              />
            </div>
            <div class="col-md-7 ">
              <div class="card-block custom_padding">
                <h4 class="card-title custom-card-title">Short Videos </h4>
                <p class="card-text">
                A well-executed series of videos can do wonders for your brand on social media. But if it flops, 
                that's a lot of wasted time and effort. If you're looking for the secret sauce to making videos that pop, 
                cimeza africa is here for you The short-video approach is designed to offer viewers a much different
                 viewing experience than with traditional longer-form videos. Case in point are Vine's six-second videos, 
                 which are immediate and without pretense. They're also short enough to upload and view quickly,
                 even on slower mobile connections. Instagram went for slightly longer videos that enable companies to upload new or existing 15-second commercials. 

                </p>
                <br />
                <br />

                <a className="btn circle btn-md custom-blue text-white fw-bold">
                  <NavLink to="/Contact">
                    {" "}
                    <span className="text-white">Request this Service</span>
                  </NavLink>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ShortVideos;
