import React from 'react'
import Services from '../view/Services'
function Modal() {
  
  return (
    <>
    {/* modal starts here */}
{/* <!-- Modal Fullscreen xl --> */}
<div className="modal modal-fullscreen-xl" id="modal-fullscreen-xl" tabindex="-1" role="dialog" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header mt-0">
        {/* <h5 class="modal-title text-success">ALL SERVICES</h5> */}
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        
      </div>
      <div className="modal-body ">
      <Services/>
      </div>
      <div className="modal-footer">
        {/* <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> */}
       
      </div>
    </div>
  </div>
</div>
{/* modal ends here */}
</>
  )
}

export default Modal