import { configureStore, findNonSerializableValue } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import FetchProductsSlice from "../redux/Slices/Products.Slice";
import ProductSlice from "../redux/Slices/Product.Slice";
import createProductSlice from "../redux/Slices/NewProduct.Slice"

const logger = createLogger();

const store = configureStore({
  reducer: {
    allProducts: FetchProductsSlice,
    product: ProductSlice,
    createdproduct: createProductSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware(findNonSerializableValue).concat(logger),
});

export default store;